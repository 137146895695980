import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router';
import Vuex from 'vuex';
import store from './store'

// import axios from 'axios';
import vuetify from './plugins/vuetify';

Vue.use(VueRouter);
Vue.use(Vuex);
// Vue.use(axios);

Vue.config.productionTip = false;

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app');



