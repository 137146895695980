import VueRouter from "vue-router";

// Layout Files
import BaseLayout from '@/layouts/BaseLayout';

// View Component Files
import Home from '@/views/Home';
import Subscription from "@/views/Subscription";
import Error from '@/views/Error';
import ConfirmationMessage from "@/views/ConfirmationMessage";

const router = new VueRouter({
	mode: 'history',
	scrollBehavior() {
		return window.scrollTo({top: 0, behavior: 'smooth'});
	},
	routes: [
		// Default landing page
		{
			name: 'index',
			path: '/index',
			redirect: 'home'
		},
		// Root level pages
		{
			path: '',
			component: BaseLayout,
			children: [
				{
					name: 'home',
					path: '/',
					component: Home
				},
				{
					name: 'confirmation',
					path: 'subscription/:slug/success',
					component: ConfirmationMessage
				},
				{
					name: 'subscription',
					path: 'subscription/:slug',
					component: Subscription
				},
			]
		},
		{
			name: 'error',
			path: '/error/:code',
			component: Error
		},
		{
			path: '*',
			redirect: '/error/404'

		},
	]
});
export default router
