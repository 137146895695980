/*
 * Firebase Configuration file
 */

import {initializeApp} from "firebase/app"
import {getFirestore, collection, addDoc, getDocs, query, where} from "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyC4WaJyrKzeavmJfeoMFn35A2x68e7b4qY",
	authDomain: "cb-artass.firebaseapp.com",
	projectId: "cb-artass",
	storageBucket: "cb-artass.appspot.com",
	messagingSenderId: "1057343826178",
	appId: "1:1057343826178:web:753bac3323412943a4ba1b",
	measurementId: "G-W0K31M13ES"
};

const firebaseApp = initializeApp(firebaseConfig);

// services
const db = getFirestore(firebaseApp);

// collection references
const requests = collection(db, 'requests')
const subscriptions = collection(db, 'subscriptions')
const provinces = collection(db, 'provinces')
const communi = collection(db, 'communi')

// export utils/refs
export {
	db,
	subscriptions,
	provinces,
	communi,
	requests,
	addDoc,
	getDocs,
	query,
	where
}
