<template>
	<v-layout wrap justify-start>
		<v-flex xs12 pt-12>
			<v-layout wrap justify-center>
				<v-flex py-4 v-if="index !== undefined">
					<card :title="cardItems[index].title" :description="cardItems[index].description" :icon="cardItems[index].icon" :value="cardItems[index].value" :pdf="cardItems[index].pdf" :pdfSinistri="cardItems[index].pdfSinistri" :btn="'cambia'"></card>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex xs12 text-center py-12 >
			<span class="subscription-title">DATI PER SOTTOSCRIZIONE POLIZZA</span>
		</v-flex>
		<v-flex xs12 :key="$route.fullPath">
			<NewForm />
		</v-flex>
	</v-layout>
</template>

<script>
import _ from "lodash";
import Card from "@/components/Card";
//import Company from "@/components/subscription/Company";
//import Individual from "@/components/subscription/Individual";
import NewForm from "@/components/subscription/NewForm";

export default {
	name: "Subscription",
	components: {
		Card,
		//Company,
		//Individual
		NewForm
	},
	data() {
		return {
			index: undefined
		}
	},
	created() {
		let slug = this.$route.params.slug
		this.index = _.findIndex(this.cardItems, function(elm) {return elm.slug === slug })
	},
	computed: {
		cardItems() {
			return this.$store.state.cardItems
		}
	},
}
</script>

<style scoped>
.subscription-title {
	font-family: 'Montserrat', sans-serif;
	color: #204177;
	font-size: 26px;
	font-weight: 600;
}
.confirmation-message{
	color: #273B60;
	background: #D1E0FB 0% 0% no-repeat padding-box;
	padding: 50px;
	border-radius: 35px;
	margin-top: 100px;
	text-align: center;
}

</style>
