<template>
	<v-card rounded="xl" style="box-shadow: 8px 8px 24px #090E1466;">
		<v-layout wrap justify-center>
			<v-flex xs12 py-8 px-8>
				<v-layout wrap justify-start>
					<v-flex xs3 md1 text-center>
						<v-avatar color="#4579F508" size="80">
							<v-img :src="_icon" height="40" contain></v-img>
						</v-avatar>
					</v-flex>
					<v-flex xs9 md8 pr-2>
						<v-layout wrap justify-start>
							<v-flex xs12>
								<span class="card-title">{{ title  }}</span>
							</v-flex>
							<v-flex xs12>
								<span class="card-description">{{ description }}</span>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
				<v-layout wrap justify-end pt-12>
					<v-flex xs12 md3 align-self-end class="text-center">
						<div class="div-container">
						<div class="btn-container">
							<div class="secondary-btn-container">
								<a :href="pdfSinistri"  target="_blank" class="secondary-btn">SCHEDA POLIZZA</a>
							</div>
						</div>
						<div class="btn-container">
							<div class="secondary-btn-container">
								<a :href="pdf" class="secondary-btn"  target="_blank">INFORMATIVA POLIZZA</a>
							</div>
						</div>

							<div class="btn-container">
							<div class="secondary-btn-container">
								<a @click="$emit('test', title)" class="secondary-btn"  target="_blank">RICHIEDI INFORMAZIONI</a>
							</div>
						</div>
						<div class="btn-container">
						<v-btn v-if="btn === 'cambia'" depressed color="#D1E0FB" :to="{name:'home'}">
							<span class="card-button">CAMBIA POLIZZA</span>
						</v-btn>
						<v-btn v-if="btn===''" depressed color="#D1E0FB" :to="{name:'subscription',params:{type:value, slug:slug, formtype: '1'}}">
							<span class="card-button">ACQUISTA POLIZZA</span>
						</v-btn>
						<v-btn v-if="btn==='home'" depressed color="#D1E0FB" :to="{name:'home'}">
							<span class="card-button">TORNA ALLA HOME</span>
						</v-btn>
						</div>
						</div>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</v-card>
</template>

<script>
export default {
	name: "Card",
	emits: ['test'],
	props: {
		title: {
			type: String,
			default: '',
		},
		slug: {
			type: String,
			default: ''
		},
		btn: {
			type: String,
			default: ''
		},
		description: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		pdf: {
			type: String,
			default: '',
		},
		pdfSinistri: {
			type: String,
			default: '',
		}
	},
	computed: {
		_icon() {
			return require('@/assets/icons/' + this.icon + `.svg`);
		}
	}
}
</script>

<style scoped lang="scss">
.card-title {
	font-family: 'Montserrat', sans-serif;
	color: #273B60;
	font-size: 22px;
	font-weight: 600;
}

.card-description {
	font-family: 'Montserrat', sans-serif;
	color: #273B60;
	font-size: 14px;
	font-weight: 500;
}

.card-button {
	font-family: 'Nunito', sans-serif;
	color: #273B60;
	font-size: 14px;
	font-weight: 600;
	min-width: 180px;
	max-width: 180px;

}
.secondary-btn{
	font-family: 'Nunito', sans-serif;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #273B60;
	color: #273B60;
	background-color: white;
	padding: 8px;
	font-size: 14px;
	font-weight: 600;
	width: 212px;
	height: 36px;
	border-radius: 4px;
}
a{
	text-decoration: none;
}
.btn-container{
	padding: 8px;
}
.secondary-btn-container{
	display: flex;
	justify-content: center;
	align-items: center;
}

  @media only screen and (max-width: 969px){
		.div-container{
			flex-wrap: wrap;
		}
	}

  @media only screen and (min-width: 600px) {
	.div-container{
		display: flex;
		flex-direction: row;
		justify-content: end;
	}
}

</style>
