<template>
	<v-layout wrap justify-start>
		<v-flex xs12 sm8 xl6>
			<v-card color="#3D5C70" class="rounded-b-pill" height="80px" flat>
				<router-link :to="{name:'home'}">
					<v-layout wrap justify-start pt-4>
						<v-flex xs4 md6 lg5>
							<v-img src="@/assets/artass.png" :height="$vuetify.breakpoint.mdAndUp?`50px`:'40px'" contain class="artass-logo"></v-img>
						</v-flex>
						<v-flex xs8 md6 lg7>
							<v-img src="@/assets/Logo trasparente white.png" :height="$vuetify.breakpoint.mdAndUp?`50px`:'40px'" contain></v-img>
						</v-flex>
					</v-layout>
				</router-link>
			</v-card>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: "Header"
}
</script>

<style scoped>

</style>
