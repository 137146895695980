<template>
	<v-layout wrap justify-center>
		<v-flex xs12 lg10>
			<Header/>
		</v-flex>
		<v-flex xs12 lg10 pb-12 px-2 px-lg-0>
			<router-view></router-view>
		</v-flex>
		<v-flex xs12 pt-12>
			<Footer/>
		</v-flex>
	</v-layout>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
	components: {
		Header,
		Footer
	}
}
</script>
